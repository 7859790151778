function moveTitle() {
  const body = document.querySelector('body');
  if (body.classList.contains('p-lenslineup')) {
    const title = document.querySelector('.title');
    const catchPhrase = document.querySelector('.catch');
    const innerDiv = document.querySelector('.outline-sec .inner');

    if (window.innerWidth <= 768) {
      // 768px以下の場合、titleをcatchPhraseの上に移動
      if (!catchPhrase.previousElementSibling || catchPhrase.previousElementSibling !== title) {
        catchPhrase.parentNode.insertBefore(title, catchPhrase);
      }
    } else {
      // 769px以上の場合、titleを元の位置に戻す
      const originalInner = document.querySelector('.pagehead-sec .inner');
      if (!originalInner.contains(title)) {
        originalInner.appendChild(title);
      }
    }
  }
}

// ページロード時とリサイズ時に関数を呼び出し
window.addEventListener('load', moveTitle);
window.addEventListener('resize', moveTitle);
